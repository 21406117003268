@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "roboto";
  src: url("../public/font/BebasNeue-Regular.ttf");
}
@font-face {
  font-family: "over";
  src: url("../public/font/Overpass-Regular.ttf");
}
@font-face {
  font-family: "over-bold";
  src: url("../public/font/Overpass-Bold.ttf");
}
@font-face {
  font-family: "namsan";
  src: url("../public/font/SeoulNamsanM.ttf");
}
@font-face {
  font-family: "hangang";
  src: url("../public/font/SeoulHangangM.ttf");
}
@font-face {
  font-family: "dohyeon";
  src: url("../public/font/DoHyeon-Regular.ttf");
}
@font-face {
  font-family: "jua";
  src: url("../public/font/Jua-Regular.ttf");
}
@font-face {
  font-family: "gamtan";
  src: url("../public/font/Gamtan-Road.ttf");
}
@font-face {
  font-family: "GGothicssi40g";
  src: url("../public/font/HGGGothicssi_Pro_40g.otf");
}
@font-face {
  font-family: "GGothicssi80g";
  src: url("../public/font/HGGGothicssi_Pro_80g.otf");
}
@font-face {
  font-family: "GGothicssi20g";
  src: url("../public/font/HGGGothicssi_Pro_20g.otf");
}

body {
  font-family: "GGothicssi40g";
}
